import PlatformActions from './PlatformActions'

export default class SPADESLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'SG-SLOT-098',
      platform: 'SPADE',
    }

    this.gameName = 'Royal Katt'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20211223150434130078392
      roundId: txnId, // 20211223150434130078392
      betType: null,
      currency,
      betTime, // 2021-12-23T15:04:34.000+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
      hasFreeSpin,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20211223150434130078392
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2021-12-23T15:04:34.527+08:00
      roundId: txnId, // 20211223150434130078392
      betType: null,
      betTime, // 2021-12-23T15:04:34.000+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      ...(hasFreeSpin && {
        gameInfo: {
          specialGame: {
            sequence: '0',
            count: '1',
            type: 'Bonus',
          },
        },
      }),
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 20211223150434130078392
      roundId: txnId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getFreeSpinMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      refPlatformTxId,
      updateTime,
      betTime,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20211223150438157079189
      refPlatformTxId, // 20211223150434130078392
      updateTime, // 2021-12-23T15:04:38.217+08:00
      roundId: txnId,
      betType: null,
      betTime, // 2021-12-23T15:04:38.000+08:00
      turnover: 0,
      betAmount: 0,
      winAmount,
      gameInfo: {
        specialGame: {
          sequence: '1',
          count: '1',
          type: 'Bonus',
        },
      },
    }))

    const message = {
      action: 'freeSpin',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: this.constantTxnData.platform,
      currency,
      userId,
      amount: betAmount,
      promotionTxId: `${promotionTxId}`, // 20220812092959204010011
      promotionId: '843',
      promotionTypeId: '14',
      txTime, // 2022-08-12T09:29:58.000+08:00
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
