import axios from 'axios'
import platformRequests from '../platformRequests'

import ALL_PLATFORM_TEST_SCENARIO_LIST from '../testScenarioList'

/* eslint-disable camelcase, no-await-in-loop */
export default class PlatformTestScenarios {
  constructor(formData) {
    this.testReportData = []
    this.formData = formData

    const { platform } = formData
    this.requests = new platformRequests[platform](formData)
    this.testScenarioList = ALL_PLATFORM_TEST_SCENARIO_LIST[platform]
    this.executeAfterTestScenarioFinished = () => {}
  }

  async runTestScenarios() {
    try {
      const res = await axios.get('/api/getActionTimeout')
      this.requests.tests.validations.actionTimeoutData = res.data || []
    } catch (error) {
      console.error(error)
    }

    const { skippedTestScenarios } = this.formData
    const testScenarioNoList = Object.keys(this.testScenarioList).filter(scenarioNo => !skippedTestScenarios.includes(scenarioNo))

    let testedScenarioCounts = 0
    for (let i = 0; i < testScenarioNoList.length; i += 1) {
      if (i + 1 === testScenarioNoList.length) this.isLastScenario = true

      const scenarioNo = testScenarioNoList[i]
      this.requests.scenarioNo = scenarioNo

      const { functionName } = this.testScenarioList[scenarioNo]
      await this[functionName]()

      testedScenarioCounts += 1
      this.emitTestedScenarioCounts(testedScenarioCounts)
    }
  }

  getTestReportData() {
    return this.testReportData
  }

  async setTestScenario(requestsCallback, executeAfterTestScenarioFinished) {
    const { scenarioNo } = this.requests
    this.requests.emptyRequests()

    try {
      await requestsCallback()
      if (executeAfterTestScenarioFinished && this.isLastScenario) executeAfterTestScenarioFinished()
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      if (executeAfterTestScenarioFinished) executeAfterTestScenarioFinished()
      throw e
    } finally {
      const { name: scenarioName } = this.testScenarioList[scenarioNo]

      const requests = this.requests.requestsInTestScenario
      const scenarioPassed = !!requests.length && requests.every(({ passed }) => passed)

      this.testReportData.push({
        scenario: `${scenarioNo}. ${scenarioName}`,
        requests,
        passed: scenarioPassed,
      })
    }
  }

  async bet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_voidBet_unvoidBet_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
        await this.requests.unvoidBet()
        await this.requests.getBalance({ action: 'unvoidBet' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleLose_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_unsettle_settleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_unsettle_settleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleTie_unsettle_settleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_settleWin_unsettle_voidBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.unsettle({ options: { resettleResult: 'void' } })
        await this.requests.getBalance({ action: 'unsettle' })
        await this.requests.voidBet()
        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_settleWin_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_settleLose_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_settleTie_voidSettle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.voidSettle()
        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async bet_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        await this.requests.cancelBet()
      },
    )
  }

  async bet_cancelBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        const summaryBetReqId = this.requests.actions.summaryReqId
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryRefId = this.requests.actions.summaryReqId

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId = summaryBetReqId
          await this.requests.cancelBet({ timesLoop })
        }
      },
    )
  }

  async cancelBetOnly() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        if (this.requests.actions.summaryReqId) {
          this.requests.actions.summaryReqId += 1
          this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        }

        await this.requests.cancelBet({ options: { beforeBet: true } })
      },
    )
  }

  async cancelBet_bet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        if (this.requests.actions.summaryReqId) {
          this.requests.actions.summaryReqId += 1
          this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        }

        await this.requests.cancelBet({ options: { beforeBet: true } })
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId -= 2

        await this.requests.bet({ options: { afterCancelBet: true } })
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId += 1

        await this.requests.getBalance({ action: 'betAfterCancelBet' })
      },
    )
  }

  async bet1_bet2_bet3_cancelBet123() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let roundIdx = 0; roundIdx < 3; roundIdx += 1) {
          await this.requests.bet({
            betIndexes: [roundIdx],
            options: { differentRound: true },
          })
        }

        await this.requests.cancelBet({ betIndexes: [0, 1, 2] })
      },
    )
  }

  async tip() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.tip()
      },
    )
  }

  async tip_cancelTip() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.tip()
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        await this.requests.cancelTip()
      },
    )
  }

  async tip_cancelTip5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.tip()

        const summaryTipReqId = this.requests.actions.summaryReqId
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryRefId = this.requests.actions.summaryReqId

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId = summaryTipReqId
          await this.requests.cancelTip({ timesLoop })
        }
      },
    )
  }

  async cancelTip_tip() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        if (this.requests.actions.summaryReqId) {
          this.requests.actions.summaryReqId += 1
          this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        }

        await this.requests.cancelTip({ options: { beforeTip: true } })
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId -= 2

        await this.requests.tip({ options: { afterCancelTip: true } })
        if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId += 1

        await this.requests.getBalance({ action: 'tipAfterCancelTip' })
      },
    )
  }

  async cancelTipOnly() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        if (this.requests.actions.summaryReqId) {
          this.requests.actions.summaryReqId += 1
          this.requests.actions.summaryRefId = this.requests.actions.summaryReqId
        }

        await this.requests.cancelTip({ options: { beforeTip: true } })
      },
    )
  }

  async give() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.give()
        await this.requests.getBalance({ action: 'give' })
      },
    )
  }

  async give5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.give({ timesLoop })
        }

        await this.requests.getBalance({ action: 'give' })
      },
    )
  }

  async bet_settleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        const summaryBetReqId = this.requests.actions.summaryReqId

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId = summaryBetReqId
          await this.requests.settleWin({ timesLoop })
        }

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async bet_asyncSettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        if (this.requests.actions.summaryReqId) {
          this.requests.actions.summaryReqId += 1
          this.requests.actions.isAsync = true
        }

        await Promise.all(Array.from({ length: 5 }).map(async (_, timesLoop) => {
          await this.requests.settleWin({
            timesLoop,
            options: { isAsync: true },
          })
          return true
        }))

        await this.requests.getBalance({ action: 'asyncSettle' })
        delete this.requests.actions.isAsync
      },
    )
  }

  async bet_voidBet5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        const summaryBetReqId = this.requests.actions.summaryReqId

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          if (this.requests.actions.summaryReqId) this.requests.actions.summaryReqId = summaryBetReqId
          await this.requests.voidBet({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidBet' })
      },
    )
  }

  async bet_settleWin_voidSettle5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.voidSettle({ timesLoop })
        }

        await this.requests.getBalance({ action: 'voidSettle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unsettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_settleLose1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unsettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_settleTie1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unsettle({
          betIndexes: [0],
          options: { resettleResult: 'tie' },
        })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_voidBet1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unsettle({
          betIndexes: [0],
          options: { resettleResult: 'void' },
        })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_unsettle1_voidBet1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unsettle({
          betIndexes: [0],
          options: { resettleResult: 'void' },
        })
        await this.requests.getBalance({
          action: 'unsettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_voidBet1_bet2_unvoidBet1_settleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.voidBet({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'voidBet' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.unvoidBet({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'unvoidBet',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleLose({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_voidSettle1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'settle',
          betIndexes: [0],
        })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.voidSettle({ betIndexes: [0] })
        await this.requests.getBalance({
          action: 'voidSettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({
          action: 'settle',
          betIndexes: [1],
        })
      },
    )
  }

  async bet_settleWin_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleWin_increased() {
    await this.setTestScenario(
      async () => {
        const options = {
          resettleResult: 'win',
          resettleAmountChange: 'increased',
        }

        await this.requests.getBalance()
        await this.requests.bet({ options })
        await this.requests.settleWin({ options })
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleWin_decreased() {
    await this.setTestScenario(
      async () => {
        const options = {
          resettleResult: 'win',
          resettleAmountChange: 'decreased',
        }

        await this.requests.getBalance()
        await this.requests.bet({ options })
        await this.requests.settleWin({ options })
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleWin_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleWin()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleTie_resettleWin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'win' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleTie_resettleLose() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'lose' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleTie_resettleTie() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleTie()
        await this.requests.getBalance({ action: 'settle' })
        await this.requests.resettle({ options: { resettleResult: 'tie' } })
        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async bet_settleLose_resettleWin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()
        await this.requests.settleLose()
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.resettle({
            options: { resettleResult: 'win' },
            timesLoop,
          })
        }

        await this.requests.getBalance({ action: 'resettle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_settle() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 5
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
          betIndexes.push(betIndex)
        }

        await this.requests.settle({
          betIndexes,
          settleInfo: {
            winBetIndexes: [0, 2, 4],
          },
        })

        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async multiBets_bet1_bet2_bet3_bet4_bet5_cancelBet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const BET_LENGTH = 5
        const betIndexes = []
        for (let betIndex = 0; betIndex < BET_LENGTH; betIndex += 1) {
          await this.requests.bet({ betIndexes: [betIndex] })
          betIndexes.push(betIndex)
        }

        await this.requests.cancelBet({ betIndexes })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })
        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_resettleTie1_settleTie2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: { differentRound: true },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'tie' },
        })
        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleTie({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async negativeBalance_bet1_settleWin1_bet2_resettleLose1_settleWin2() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet({ betIndexes: [0] })

        await this.requests.settleWin({ betIndexes: [0] })
        await this.requests.getBalance({ action: 'settle' })

        await this.requests.betForNegativeBalance({
          betIndexes: [1],
          options: {
            differentRound: true,
          },
        })

        await this.requests.resettle({
          betIndexes: [0],
          options: { resettleResult: 'lose' },
        })
        await this.requests.getBalance({
          action: 'resettle',
          options: { validateNegativeBalance: true },
        })

        await this.requests.settleWin({ betIndexes: [1] })
        await this.requests.getBalance({ action: 'settle' })
      },
    )
  }

  async positiveBalance_bet() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const betAmount = this.requests.tests.lastBalance[0] + this.formData.betAmount

        await this.requests.bet({
          data: { betAmount },
          options: { validateInsufficientBalance: true },
        })
      },
    )
  }

  async positiveBalance_bet_tip() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()

        const betAmount = this.requests.tests.lastBalance[0] + this.formData.betAmount

        await this.requests.bet({
          data: { betAmount },
          options: { validateInsufficientBalance: true },
        })

        await this.requests.tip({
          data: { tip: betAmount },
          options: { validateInsufficientBalance: true },
        })
      },
    )
  }

  async zeroBalance_bet() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('zero')

        await this.requests.getBalance({
          options: { validateZeroBalance: true },
        })

        await this.requests.bet({
          options: { validateInsufficientBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async zeroBalance_bet_tip() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('zero')

        await this.requests.getBalance({
          options: { validateZeroBalance: true },
        })

        await this.requests.bet({
          options: { validateInsufficientBalance: true },
        })

        await this.requests.tip({
          options: { validateInsufficientBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async negativeBalance_bet() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('negative')

        await this.requests.getBalance({
          options: { validateNegativeBalance: true },
        })

        await this.requests.bet({
          options: { validateInsufficientBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async negativeBalance_bet_tip() {
    await this.setTestScenario(
      async () => {
        await this.insufficientBalanceBetTestPromise('negative')

        await this.requests.getBalance({
          options: { validateNegativeBalance: true },
        })

        await this.requests.bet({
          options: { validateInsufficientBalance: true },
        })

        await this.requests.tip({
          options: { validateInsufficientBalance: true },
        })
      },
      this.finishInsufficientBalanceBetTest,
    )
  }

  async bet_settleWin_freeSpin() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.settleWin({ options: { hasFreeSpin: true } })
        await this.requests.getBalance({ action: 'settle' })

        const count = 5
        for (let sequence = 1; sequence < count + 1; sequence += 1) {
          await this.requests.freeSpin({ count, sequence })
          await this.requests.getBalance({ action: 'freeSpin' })
        }
      },
    )
  }

  async bet_settleLose_freeSpin5Times() {
    await this.setTestScenario(
      async () => {
        await this.requests.getBalance()
        await this.requests.bet()

        await this.requests.settleLose({ options: { hasFreeSpin: true } })
        await this.requests.getBalance({ action: 'settle' })

        for (let timesLoop = 0; timesLoop < 5; timesLoop += 1) {
          await this.requests.freeSpin({ timesLoop })
        }
        await this.requests.getBalance({ action: 'freeSpin' })
      },
    )
  }
}
