<template>
  <AntRow>

    <AntCol :span="24">
      <!-- <p><b>searchState:</b> {{ searchState }}</p> -->
      <AntRow :gutter="16" class="search-row">
        <AntCol :span="6">
          <AntSelect
            placeholder="Currency Code"
            v-model:value="searchState.currency"
            :options="dataSource.map(({ key }) => ({ label: key, value: key })).sort((a, b) => a.value.localeCompare(b.value))"
            class="search-input"
            showSearch
            allowClear
            filterOption
          />
        </AntCol>

        <AntCol :span="4">
          <AntButton
            type="primary"
            html-type="submit"
            @click="onSearch"
          >
            Search
          </AntButton>
        </AntCol>

      </AntRow>

      <!-- <p><b>editableData:</b> {{ editableData }}</p>
      <p><b>validateState:</b> {{ validateState }}</p> -->
      <AntTable
        :columns="columns"
        :dataSource="displayedDataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 50,
          pageSizeOptions: ['50', '100', '300'],
          showSizeChanger: true,
        }"
        :showSorterTooltip="false"
        bordered
        pageSizeOptions
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'equivalent'">
            {{
              text == null
                ? ''
                : text.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 10,
                })
            }}
          </template>

          <template v-if="editableFieldsRuleMap[column.dataIndex]">
            <AntFormItem
              v-if="column.dataIndex === 'rate' ? !text && editableData[record.key] : editableData[record.key]"
              :id="column.dataIndex"
              :name="column.dataIndex"
              :validateStatus="validateState[record.key]?.[column.dataIndex]?.validateStatus"
              :help="validateState[record.key]?.[column.dataIndex]?.message"
            >
              <AntInput
                v-model:value="editableData[record.key][column.dataIndex]"
                @blur="() => onValidateField(record.key, column.dataIndex, editableFieldsRuleMap[column.dataIndex])"
              />
            </AntFormItem>
            <template v-else>
              <div
                v-if="column.dataIndex === 'rate'"
                :title="record.rateLastUpdated"
                :style="!text && { color: 'red'}"
              >
                {{ !text ? 'Need a fixed rate with THB' : text }}
              </div>
              <div
                v-else-if="column.dataIndex === 'realCurrency'"
                :style="!text && { color: 'red'}"
              >
                {{ !text ? 'Need to be updated' : text }}
              </div>
              <div v-else>{{ text }}</div>
            </template>
          </template>

          <template v-if="column.dataIndex === 'operation'">
            <span v-if="editableData[record.key]">
              <a
                v-if="Object.values(validateState[record.key]).every(({ validateStatus }) => validateStatus === 'success')"
                @click="onSave(record.key, record)"
              >
                Save
              </a>
              <AntDivider type="vertical" />
              <a @click="onCancel(record.key)">Cancel</a>
            </span>
            <a v-else @click="onEdit(record.key)">Edit</a>
          </template>
        </template>
      </AntTable>

    </AntCol>
  </AntRow>
</template>

<script>
import moment from 'moment'

import {
  ref,
  reactive,
  watch,
  computed,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Table as AntTable,
  Input as AntInput,
  Select as AntSelect,
  Button as AntButton,
  Divider as AntDivider,
  message as antMessage,
  Form as AntForm,
} from 'ant-design-vue'

import { updateCurrency } from '@/api/testerportal'

export default {
  name: 'RatioTable',
  components: {
    AntRow,
    AntCol,
    AntTable,
    AntInput,
    AntSelect,
    AntButton,
    AntDivider,
    AntFormItem: AntForm.Item,
  },
  props: {
    ratioData: Array,
    loginUserData: Object,
    permissionData: Object,
  },
  setup(props) {
    const ratioData = reactive(props.ratioData)
    const editableData = reactive({})

    const searchState = reactive({})

    const columns = computed(() => ([
      {
        title: 'Currency Code',
        dataIndex: 'key',
        width: 60,
        align: 'center',
        sorter: (a, b) => a.key.localeCompare(b.key),
      },
      {
        title: 'Real Currency',
        dataIndex: 'realCurrency',
        align: 'center',
        width: 60,
        sorter: (a, b) => (a.realCurrency || '').localeCompare((b.realCurrency || '')),
      },
      {
        title: 'Ratio',
        dataIndex: 'ratio',
        width: 40,
        align: 'center',
      },
      {
        title: 'Ratio with THB',
        dataIndex: 'rate',
        width: 80,
        align: 'right',
      },
      {
        title: 'Equivalent in 1000 THB',
        dataIndex: 'equivalentInTHB',
        width: 80,
        align: 'right',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        width: 100,
      },
      ...((props.permissionData.RatioTable_Edit || []).includes(props.loginUserData.permission) ? [{
        title: 'Operation',
        dataIndex: 'operation',
        width: 50,
      }] : []),
    ]))

    const dataSource = computed(() => ratioData.map(({ currency, ...d }) => ({ key: currency, ...d })).sort((a, b) => {
      if (!a.realCurrency && !b.realCurrency) return a.key.localeCompare(b.key)
      if (!a.realCurrency) return -1
      if (!b.realCurrency) return 1
      return a.realCurrency.localeCompare(b.realCurrency)
    }))

    const displayedDataSource = ref(dataSource.value)

    watch(dataSource, (newVal, oldVal) => {
      // console.log('watch dataSource to set displayedDataSource', newVal, oldVal)
      if (!Object.keys(oldVal).length && !!Object.keys(newVal).length) displayedDataSource.value = dataSource.value
    })

    watch(searchState, () => {
      if (Object.values(searchState).every(d => !d || !d.length)) displayedDataSource.value = dataSource.value
    })

    const onSearch = () => {
      displayedDataSource.value = dataSource.value.filter((d) => {
        let matched = true
        if (searchState.currency) matched = matched && d.key.includes(searchState.currency)
        return matched
      })
    }

    const editableFieldsRuleMap = {
      rate: [
        {
          pattern: /^\d*\.?\d*$/,
          message: 'Invalid format',
          trigger: 'blur',
        },
        {
          required: true,
          message: 'Required',
          trigger: 'blur',
        },
      ],
      realCurrency: [
        {
          required: true,
          message: 'Required',
          trigger: 'blur',
        },
      ],
      ratio: [
        {
          pattern: /^\d+:\d+$/,
          message: 'Invalid format',
          trigger: 'blur',
        },
        {
          required: true,
          message: 'Required',
          trigger: 'blur',
        },
      ],
      remark: [],
    }

    const validateState = reactive({})

    const onValidateField = (currency, field, rules) => {
      if (!rules || !rules.length) return
      const requiredRule = rules.find(({ required }) => required)
      const patternRule = rules.find(({ pattern }) => !!pattern)

      if (!validateState[currency]) validateState[currency] = {}
      if (!validateState[currency][field]) validateState[currency][field] = {}

      if (requiredRule && !editableData[currency][field]) {
        validateState[currency][field].validateStatus = 'error'
        validateState[currency][field].message = requiredRule.message
      } else if (patternRule && !patternRule.pattern.test(editableData[currency][field])) {
        validateState[currency][field].validateStatus = 'error'
        validateState[currency][field].message = patternRule.message
      } else {
        validateState[currency][field].validateStatus = 'success'
        delete validateState[currency][field].message
      }
    }

    const onEdit = (currency) => {
      const data = dataSource.value.find(d => currency === d.key)
      editableData[currency] = Object.keys(data).reduce((acc, key) => {
        if (editableFieldsRuleMap[key]) {
          acc[key] = data[key]
        }
        return acc
      }, {})
      validateState[currency] = {}
    }

    const onSave = async (currency, record) => {
      const messageKey = `updateCurrency-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const updateData = { currency, ...editableData[currency] }

      if (editableData[currency].rate) {
        updateData.rate = Number(updateData.rate)
        updateData.rateLastUpdated = moment().toISOString(true)
      }

      const { data, message } = await updateCurrency(updateData) // eslint-disable-line

      if (data) {
        const ratioArr = updateData.ratio.split(':')
        const computedRatio = ratioArr[0] / ratioArr[1]
        const equivalentInTHB = Number((computedRatio * record.rate * 1000).toFixed(3))
        const updateRatioData = {
          ...updateData,
          equivalentInTHB,
        }

        Object.assign(ratioData.find(d => d.currency === currency), updateRatioData)
        Object.assign(dataSource.value.find(d => d.key === currency), updateRatioData)
        displayedDataSource.value = dataSource.value
        delete editableData[currency]

        antMessage.success({
          key: messageKey,
          content: `Update currency: ${currency} successfully`,
        })
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update currency: ${message}`,
        })
      }
    }

    const onCancel = (currency) => {
      delete editableData[currency]
      delete validateState[currency]
    }

    return {
      searchState,
      onSearch,
      dataSource,
      displayedDataSource,
      columns,
      editableData,
      editableFieldsRuleMap,
      onEdit,
      onSave,
      onCancel,
      validateState,
      onValidateField,
    }
  },
}
</script>

<style scoped>
.search-row {
  margin-bottom: 28px;
}

.search-input {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0;
  text-align: left;
}
</style>
